import React from "react"
import BoxedLayout from "../components/layouts/BoxedLayout"
import Seo from "../components/seo"

const IndexPage = data => {
  return (
    <BoxedLayout>
      <Seo title="Contact Tzook" />
      <p>
        Hey, this is my new site on Gatsby...
        <br />
        So until I have my serverless setup with a nice contact form.
      </p>
      <p>
        You can just contact me over email:
        <br />
        tzook AT gmail com :)
      </p>      
    </BoxedLayout>
  );
}

export default IndexPage
